.services-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .services-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .services-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  
  .service-item {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
  }
  
  .service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .service-item img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .service-content {
    padding: 1rem;
  }
  
  .service-item h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .service-item p {
    font-size: 1.1rem;
    color: #555;
  }
  