.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
    animation: fadeIn 0.3s ease-in-out;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    animation: slideIn 0.3s ease-in-out;
}

.modal-content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-content img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.modal-link {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #333;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.modal-link:hover {
    background-color: #555;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20%);
    }

    to {
        transform: translateY(0);
    }
}

@media screen and (min-width:375px) and (min-width: 600px) { 
    .media-content {
        max-width: 300px;
    }
}