/* ギャラリーのスタイル */
.image-gallery {
    margin-bottom: 240px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.gallery-item {
    cursor: pointer;
    width: 20%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s;
}

.gallery-item:hover img {
    transform: scale(1.2);
}

/* モーダルのスタイル */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.modal-content {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    color: white;
    cursor: pointer;
}

/* タブレット用のメディアクエリ */
@media (max-width: 1920px) {
    .gallery {
        margin-bottom: 240px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .gallery-item {
        cursor: pointer;
        width: 20%;
        height: auto;
        overflow: hidden;
        border-radius: 10px;
    }
  }

/* タブレット用のメディアクエリ */
@media (max-width: 1024px) {
    .gallery {
        width: 100%;
        margin-bottom: 240px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .gallery-item {
        cursor: pointer;
        width: 160px;
        height: 120px;
        overflow: hidden;
        border-radius: 10px;
    }
  }
  
  /* スマホ用のメディアクエリ */
@media (max-width: 768px) {
    .gallery {
        width: 100%;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }

    .gallery-item {
        cursor: pointer;
        width: 128px;
        height: 96px;
        overflow: hidden;
        border-radius: 10px;
    }
  }
  