/* src/SlideShow.css */
.slideshow-container {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  margin: auto;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.indicators {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.indicator {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.indicator.active {
  background-color: rgba(255, 255, 255, 1);
}

.slide-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 24px;
  color: white;
  animation: color-change 3s infinite;
}

@keyframes color-change {
  0% { color: red; }
  33% { color: green; }
  66% { color: blue; }
  100% { color: red; }
}

@media (max-width: 768px) {
/* src/SlideShow.css */
.slideshow-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin: auto;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
}