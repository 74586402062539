@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

html {
  font-size: 20px;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  margin: 0;
  padding: 0;
  padding-bottom: 4rem; /* フッターの高さ分の余白を確保 */
  box-sizing: border-box;
}

.header {
  background-color: #333;
  color: white;
  padding: 1rem;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  margin: 0;
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

.header nav li {
  margin: 0;
}

.header nav a {
  color: white;
  text-decoration: none;
}

.header nav a:hover {
  text-decoration: underline;
}

.footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
  /* 位置を固定しないようにposition: fixed;を削除 */
  width: 100%;
  box-sizing: border-box; /* 追加: パディングとボーダーを幅に含める */
}
