.footer {
    background-color: #333;
    color: white;
    padding: 1rem;
    width: 100%;
    bottom: 0;
}

.footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer nav ul {
    list-style: none;
    display: flex;
    gap: 1rem;
    padding: 0;
    margin: 0;
}

.footer nav li {
    margin: 0;
}

.footer nav a {
    color: white;
    text-decoration: none;
}

.footer nav a:hover {
    text-decoration: underline;
}