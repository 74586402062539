/* src/PictureFrame.css */
.frame-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.frame {
    /* #8b4513; 額縁の色 */
    padding: 10px;
    border: 15px solid #73777A;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: #817D7B; /* 額縁の背景色 */
    display: flex;
    justify-content: center;
    align-items: center;
}

.frame.landscape {
    width: 240px;
    height: 180px;
}

.frame.portrait {
    width: 180px;
    height: 240px;
}

.painting {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 5px solid #fff; /* 絵画の内側の余白 */
}

.title {
    margin-top: 10px;
    font-size: 18px;
    color: #333;
    font-family: 'Georgia', serif;
    text-align: center;
}

a {
    color: #333;
    text-decoration: none;
}

