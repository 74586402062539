.contact-container {
  max-width: 1280px;
  width: 65%;
  margin: 120px auto;
  border-radius: 10px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
  width: 100%;
}

.contact-form {
  width: 100%;
}

.form-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.form-group label {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: block;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  font-size: 1rem;
  height: 2rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s;
  padding: 0.5rem;
}

.form-group textarea {
  height: 15rem;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #333;
  outline: none;
}

.submit-button {
  width: 100%;
  height: 3rem;
  background-color: #333;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #555;
}

/* スマホ用のメディアクエリ */
@media (max-width: 480px) {
  .contact-container {
    width: 80%;
    margin: 60px auto; /* マージンを調整 */
  }

  .contact-container h2 {
    font-size: 1.2rem; /* ヘッダーのフォントサイズを調整 */
  }

  .form-group label {
    font-size: 1rem; /* ラベルのフォントサイズを調整 */
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem; /* 入力フィールドのフォントサイズを調整 */
    height: 2.5rem; /* 入力フィールドの高さを調整 */
  }

  .form-group textarea {
    height: 10rem; /* テキストエリアの高さを調整 */
  }

  .submit-button {
    font-size: 1rem;
    padding: 0.75rem 1rem; /* ボタンのパディングを調整 */
  }
}
